import { IAppDataContent } from "@/interfaces/appdata";
import { ICategoryWithProducts } from "@/interfaces/products";
import React, { useEffect, useState } from "react";
import { DefaultLayout } from "../../layouts/DefaultLayout"
import "./notListedProducts.style.scss";

const NotListedProductsPage = ({ pageContext }: CheckoutProps) => {
    
    const {
        templateData,
        privateProducts,
        locale
    } = pageContext.appData
    const { not_listed_products } = locale.not_listed_products_page
    const defaultLayoutData = {  pageContext, templateData, title: not_listed_products }

    return (
        <DefaultLayout { ...defaultLayoutData}>
            <div className="default-screen-size not_listed_products" style={{ padding: '40px'}}>
                <h1>{ not_listed_products }</h1>
                <div className="products">
                    { privateProducts.map( product => {
                        const productPath = `/${pageContext.lang}${product.currentPath}`;

                        return (
                            <ul>
                                <li>
                                    <p>{ product.name } - </p>
                                    <a href={productPath}>{productPath}</a>
                                </li>
                            </ul>
                        )
                    })}
                </div>
            </div>
        </DefaultLayout>
    )
}

interface CheckoutProps{
    pageContext: {
        appData: IAppDataContent,
        lang: string,
        categoriesWithProducts: ICategoryWithProducts[],
        currentPath: string;
        pathLang: string;
        widgetTarget: string;
    }
}

export default NotListedProductsPage;
